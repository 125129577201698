function Unauthorized($mdDialog) {
  const _showAlert = (error) => {
    // Appending dialog to document.body to cover sidenav in docs app
    // Modal dialogs should fully cover application
    // to prevent interaction outside of dialog
    $mdDialog
      .show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title(`Job Fetch Error`)
          .textContent(error)
          .ariaLabel(error)
          .ok("Ok!")
      )
      .finally(() => {
        this.onShown();
      });
  };

  this.$onChanges = cObject => {
    if (cObject.error && cObject.error.currentValue) {
      _showAlert(cObject.error.currentValue);
    }
  };
}

angular
  .module("sq.jobs.unauthorized.component", [])
  .component("sqJobUnauthorized", {
    bindings: {
      error: "<",
      onShown: "&"
    },
    template: `
    <span id='job-error'></span>
  `,
    controller: Unauthorized,
    controllerAs: "$ctrl"
  });
